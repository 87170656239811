@import '@amperio/amperio-ui/styles/main';

.desktop-sidebar-wrapper {
  align-items: center;
  background-color: palette(primary);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: spacing(md) 0;
  position: relative;
  width: 100px;

  > span {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    cursor: pointer;
    width: spacing(lg);

    > * {
      fill: gray(300);
    }
  }

  &__icons {
    display: flex;
    flex-direction: column;
    margin-top: spacing(2xl);

    > .MuiButtonBase-root {
      margin-bottom: spacing(sm);
    }
  }

  &__icon {
    @include opacity(0.4);
    cursor: pointer;
    transition: opacity 250ms ease-in;

    &--active {
      @include opacity(1);
    }
  }
}

@include breakpoint(lg) {
  .desktop-sidebar-wrapper {
    width: 75px;
  }
}
