@import '@amperio/amperio-ui/styles/main';

.events-table-wrapper {
  display: block;
  position: relative;

  &__header {
    margin-bottom: spacing(sm);
  }
}

@include breakpoint(xs) {
  .events-table-wrapper {
    &__header {
      margin-bottom: spacing(xs);
    }
  }
}
