@import '@amperio/amperio-ui/styles/main';

.events-view-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    align-items: center;
    column-gap: spacing(sm);
    display: flex;
    justify-content: space-between;
  }

  &__dropdown {
    align-self: flex-end;
    width: 300px;
  }

  &__dropdown-option {
    align-items: center;
    display: flex;

    > *:first-child {
      margin-right: spacing(xs);
    }
  }
}

.header__calendar-actions {
  align-items: center;
  column-gap: spacing(md);
  display: flex;
  justify-content: space-between;

  &--mobile {
    margin: spacing(xs) 0 auto auto;
    width: 95%;
  }
}

.header__events-actions {
  align-items: center;
  column-gap: spacing(md);
  display: flex;
}

.header__switch {
  margin: 0 spacing(xs);
}

.calendar-actions__arrows {
  display: flex;

  &--mobile {
    margin: 0;
  }
}
