@import '@amperio/amperio-ui/styles/main';

.account-form-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: spacing(xs);

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing(sm);
    justify-content: space-evenly;
    position: relative;
  }
}

@include breakpoint(xs) {
  .account-form-wrapper__form {
    gap: spacing(xs);
  }
}
