@import '@amperio/amperio-ui/styles/main';

.desktop-header-wrapper {
  align-items: center;
  background-color: gray(300);
  border-bottom: 2px solid gray(500);
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 spacing(md);
  position: relative;
  width: 100%;

  &__right-container {
    align-items: center;
    display: flex;

    > .icon-wrapper {
      margin-right: spacing(sm);
    }
  }
}

@include breakpoint(md) {
  .desktop-header-wrapper {
    padding: 0 spacing(sm);
  }
}
