@import '@amperio/amperio-ui/styles/main';

.upsert-lead-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;

    > span > .button-wrapper:not(:last-child) {
      margin-right: spacing(md);
    }
  }

  &__content {
    margin-top: spacing(md);
    width: 40%;
  }
}

@include breakpoint(md) {
  .upsert-lead-wrapper {
    &__header {
      > span > .button-wrapper:not(:last-child) {
        margin-right: spacing(sm);
      }
    }

    &__content {
      margin-top: spacing(sm);
      width: 50%;
    }
  }
}

@include breakpoint(sm) {
  .upsert-lead-wrapper {
    &__content {
      margin-top: 0;
      width: 75%;
    }
  }
}

@include breakpoint(xs) {
  .upsert-lead-wrapper {
    &__content {
      width: 100%;
    }
  }
}
