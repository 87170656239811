@import '@amperio/amperio-ui/styles/main';

.upsert-event-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;

    > span > .button-wrapper:first-child {
      margin-right: spacing(md);
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: spacing(md) 0;

    &-left > *:last-child {
      margin-top: spacing(md);
    }

    > *:first-child {
      margin-right: spacing(md);
    }

    .summary-form-wrapper,
    .product-categories-form-wrapper {
      height: fit-content;
    }

    .summary-form-wrapper {
      grid-column: 1 / 3;
      margin-top: spacing(md);
    }
  }
}

@include breakpoint(md) {
  .upsert-event-wrapper {
    &__header {
      > span > .button-wrapper:first-child {
        margin-right: spacing(sm);
      }
    }

    &__content {
      display: block;
      padding: spacing(sm) 0;

      &-left > *:last-child {
        margin-top: spacing(sm);
      }

      > *:first-child {
        margin-right: 0;
      }

      > .product-categories-form-wrapper {
        margin-top: spacing(sm);
      }

      .summary-form-wrapper {
        margin-top: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .upsert-event-wrapper {
    &__content {
      padding: 0;
      padding-bottom: spacing(2xl);
    }
  }
}

@include breakpoint(xs) {
  .upsert-event-wrapper {
    &__content {
      &-left > *:last-child {
        margin-bottom: 0;
        margin-top: spacing(xs);
      }

      > *:not(:first-child) {
        margin-top: spacing(xs);
      }
    }
  }
}
