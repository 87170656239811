@import '@amperio/amperio-ui/styles/main';

.summary-form-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: spacing(xs);

  &__form {
    display: flex;
    justify-content: space-between;

    > .MuiFormControl-root {
      margin-bottom: spacing(xs);
      width: 48%;
    }
  }
}

@include breakpoint(md) {
  .summary-form-wrapper {
    &__form {
      flex-direction: column;

      > .MuiFormControl-root {
        width: 100%;
      }
    }
  }
}
