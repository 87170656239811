@import '@amperio/amperio-ui/styles/main';

.mobile-header-wrapper {
  align-items: center;
  background-color: gray(300);
  border-bottom: 2px solid gray(500);
  display: flex;
  height: spacing(2xl);
  justify-content: space-between;
  padding: 0 spacing(sm);
  position: relative;
  width: 100%;

  > svg {
    margin-right: spacing(xs);
  }

  > h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@include breakpoint(xs) {
  .mobile-header-wrapper {
    height: spacing(xl);
    padding: 0 spacing(xs);

    > h6 {
      @include font-size(map-get($font-size, xs));
    }
  }
}
