@import '@amperio/amperio-ui/styles/main';

.accounts-list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__table-wrapper {
    height: 100%;
    padding-top: spacing(md);
    width: 100%;
  }
}

@include breakpoint(md) {
  .accounts-list-wrapper {
    &__table-wrapper {
      padding-top: spacing(sm);
    }
  }
}

@include breakpoint(sm) {
  .accounts-list-wrapper {
    &__table-wrapper {
      padding-top: 0;
    }
  }
}
