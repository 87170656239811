@import '@amperio/amperio-ui/styles/main';

.address-form-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: spacing(xs);

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing(sm);
    justify-content: space-evenly;
    position: relative;

    .form__grid {
      display: grid;
      grid-gap: spacing(sm);
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include breakpoint(sm) {
  .address-form-wrapper {
    &__form {
      .form__grid {
        grid-gap: spacing(sm);
        grid-template-columns: 1fr;
      }
    }
  }
}

@include breakpoint(xs) {
  .address-form-wrapper__form {
    gap: spacing(xs);

    .form__grid {
      grid-gap: spacing(xs);
      grid-template-columns: 1fr;
    }
  }
}
