@import '@amperio/amperio-ui/styles/main';

.accounts-table-wrapper {
  display: block;
  margin-bottom: spacing(sm);
  position: relative;

  &__body-row {
    cursor: pointer;
  }
}
