@import '@amperio/amperio-ui/styles/main';

.upsert-account-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(md);

    > span > .button-wrapper:not(:last-child) {
      margin-right: spacing(md);
    }
  }

  &__dropdown {
    align-self: flex-end;
    width: 300px;
  }

  &__dropdown-option {
    align-items: center;
    display: flex;

    > *:first-child {
      margin-right: spacing(xs);
    }
  }

  &__content {
    column-gap: spacing(md);
    padding: spacing(md) 0;

    &-top {
      display: grid;
      grid-gap: spacing(md);
      grid-template-columns: repeat(2, 1fr);

      > .account-form-wrapper,
      .address-form-wrapper {
        height: fit-content;
      }
    }

    > .events-table-wrapper {
      height: fit-content;
      margin-top: spacing(md);
    }
  }
}

@include breakpoint(md) {
  .upsert-account-wrapper {
    &__header {
      margin-bottom: spacing(sm);

      > span > .button-wrapper:not(:last-child) {
        margin-right: spacing(sm);
      }
    }

    &__content {
      padding: spacing(sm) 0;

      &-top {
        grid-gap: spacing(sm);
        grid-template-columns: 1fr;
      }

      > .events-table-wrapper {
        margin-top: spacing(sm);
      }
    }
  }
}

@include breakpoint(sm) {
  .upsert-account-wrapper {
    &__header {
      margin-bottom: 0;
    }

    &__content {
      padding: spacing(sm) 0;
    }
  }
}

@include breakpoint(xs) {
  .upsert-account-wrapper {
    &__dropdown {
      width: 100%;
    }

    &__content {
      padding: spacing(xs) 0;

      &-top {
        grid-gap: spacing(xs);
      }

      > .events-table-wrapper {
        margin-top: spacing(xs);
      }
    }
  }
}
