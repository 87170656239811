@import '@amperio/amperio-ui/styles/main';

.error-wrapper {
  position: relative;

  > h1 {
    font-size: 20rem;
    line-height: 20rem;
  }

  > h2 {
    text-align: center;
    width: 80%;
  }

  &__actions {
    display: flex;
    margin-top: spacing(md);

    > button {
      margin: 0 spacing(xs);
    }
  }
}

@include breakpoint(lg) {
  .error-wrapper {
    > h1 {
      font-size: 16rem;
      line-height: 16rem;
    }

    > h2 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

@include breakpoint(md) {
  .error-wrapper {
    > h1 {
      font-size: 14rem;
      line-height: 14rem;
    }

    > h2 {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }
}

@include breakpoint(sm) {
  .error-wrapper {
    > h1 {
      font-size: 12rem;
      line-height: 12rem;
    }

    > h2 {
      font-size: 1.5rem;
      line-height: 1.5rem;
      width: 90%;
    }
  }
}

@include breakpoint(xs) {
  .error-wrapper {
    > h1 {
      font-size: 8rem;
      line-height: 8rem;
    }

    > h2 {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    &__actions {
      flex-direction: column;
      margin-top: 0;

      > button {
        margin-top: spacing(sm);
      }
    }
  }
}
